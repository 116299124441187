import { combineReducers } from 'redux';
import ProductReducer from './Product';
import UserReducer from './User';
import WalkthroughReducer from "./WalkThroughReducer"
import { playBookReducer } from './playbook';


const rootReducer = combineReducers({
    products: ProductReducer,
    user: UserReducer,
    walkthrough: WalkthroughReducer,
    playBooks: playBookReducer
})


export default rootReducer;