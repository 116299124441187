import React, { useState, useEffect } from 'react';
import Fuse from "fuse.js";
import keysIn from "lodash/keysIn";
import filter from "lodash/filter";
import uniqueId from "lodash/uniqueId";
import Dropdown from 'react-bootstrap/Dropdown';
import { DEFAULT_CATEGORY } from '../../constants/constant'

import './productMenu.css';


export const ProductMenu = (props) => {
    const { allProductsAndCategories, product, allManuals, index, user } = props;
    const [fuse, setFuse] = useState(null)
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        let options = {
            keys: ["name"],
            threshold: 0.3,
            distance: 100,
            maxPatternLength: 100,
            minMatchCharLength: 3

        };
        let filteredManuals = filter(allManuals, { 'project_in': product })
        const f = new Fuse(filteredManuals, options)
        setFuse(f)
    }, [allManuals, index, product])

    useEffect(() => {
        if (!fuse) {
            return;
        }
        const searchResults = fuse.search(searchQuery).map(item => item.item);
        setSearchResults(searchResults);
    }, [searchQuery, fuse])

    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };


    return (
        <React.Fragment>
            <form autoComplete="off" id={`productSearch${index}`}>
                <div className="form-group mb-4">
                    <input id={`menuSearchControl${index}`}
                        type="text"
                        onChange={handleSearchChange}
                        placeholder={'Find a guide by its name '}
                        className="navigation-search-input navigation-search-underlined"
                        value={searchQuery}
                    />
                </div>
            </form>
            <Dropdown.Divider />
            <div className="container-fluid product-dropdown-menu">
                <div className="row">
                    {
                        !searchQuery ? (
                            <>
                                {
                                    keysIn(allProductsAndCategories[product]).map(category => (
                                        category !== DEFAULT_CATEGORY ? (
                                            <li key={uniqueId()} className="col-sm-5th-1" style={{ listStyle: "none", marginTop: '1.5rem' /* "15px" */ }}>
                                                <h3 className="category" style={{ whiteSpace: 'pre-wrap' }}>{category}</h3>
                                                {
                                                    allProductsAndCategories[product][category].map(manuals => (
                                                        <a key={manuals.display_name} href={manuals.canonical_url}>
                                                            <div className="manuals" style={{ cursor: "pointer" }}>
                                                                <label className="manual-info" style={{ whiteSpace: "pre-wrap" }}>{manuals.display_name}</label>
                                                            </div>
                                                        </a>

                                                    ))
                                                }
                                            </li>
                                        ) : (
                                            allProductsAndCategories[product][category].map(manuals => (
                                                <li key={uniqueId()} className="col-sm-5th-1" style={{ listStyle: "none", marginTop: '1.5rem' /* "15px" */ }}>
                                                    <a key={manuals.display_name} href={manuals.canonical_url}>
                                                        <div className="manuals" style={{ cursor: "pointer" }}>
                                                            <label className="manual-info" style={{ whiteSpace: "pre-wrap" }}>{manuals.display_name}</label>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))
                                        )
                                    ))
                                }
                            </>
                        ) : (
                                searchResults.map(item => (
                                    <li
                                        key={uniqueId()}
                                        className="col-12"
                                        style={{
                                            // padding: "0px 0px 5px 0px",
                                            padding: "0 0 0.5rem 0",
                                            listStyle: "none"
                                        }}
                                    >
                                        <a href={item.canonical_url}
                                            style={{ color: "#000000", textDecoration: "none" }}
                                        >
                                            <div className='menu-search-list' style={{ cursor: "pointer" }}>
                                                {item.name}
                                                <span className="search-description">{item.description}</span>

                                                {item.mannual_category !== DEFAULT_CATEGORY &&
                                                    <span className="label-search-category"
                                                        // style={{ fontSize: "11px" }}
                                                        style={{ fontSize: "1.1rem" }}
                                                    >
                                                        {item.mannual_category}
                                                    </span>
                                                }
                                            </div>

                                        </a>
                                    </li>
                                ))
                            )
                    }
                </div>
            </div>
        </React.Fragment>
    )

}