import React from "react";
import CookieConsent from "react-cookie-consent";
let currentYear = new Date().getFullYear();

const footer = () => {
  return (
    <>
    {
        (<CookieConsent   
              location="bottom"
              buttonText="ACCEPT"
              style={
                      { 
                        background: "#535876", 
                        opacity:"98%",
                        fontSize:'1.5rem',  
                      }
                    }
              buttonStyle={
                            {
                            marginRight:'5.0rem', 
                            background:'#FCCE31', 
                            color: "#383838",  
                            fontSize: "1.5rem", 
                            padding:'1.0rem 2.0rem' 
                          }
                        }
              contentStyle= {{
               textAlign:'left',
               paddingLeft:'5.0rem',
               paddingTop:'1.5rem'

              }}
              sameSite='strict'
            >
              By continuing on our website or clicking accept, you acknowledge that you have read and understand our <a style={{color:'white', textDecoration:'underline'}} target="_blank" rel="noopener noreferrer" href="https://www.logpoint.com/en/privacy-policy/">Privacy policy</a> and our <a target="_blank" style={{color:'white', textDecoration:'underline'}} rel="noopener noreferrer" href="https://www.logpoint.com/en/terms-of-service/">
            Terms of service.
              </a>{" "}
              
        </CookieConsent> 
        )
        } 
    <footer>
      <div className="copy-right">
        <div className="row">
        <div className="col-sm md-left">
          <a target="_blank" rel="noopener noreferrer" href="https://www.logpoint.com/en/privacy-policy/">Privacy policy</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.logpoint.com/en/eula/">EULA</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.logpoint.com/en/terms-of-service/">
            Terms of service
              </a>
        </div>
        <div className="col-sm md-right" style={{fontSize: '1.3rem'}}>Copyright &copy; {currentYear} Logpoint. All rights reserved.</div>
      </div>
      </div>
    </footer>
    </>
  );
};

export default footer;
