
const getVersion = (version, isDash) => {
  let constructedVersion = "";
  constructedVersion = isDash ? version.split(/[/-]+/)[1] : version;

  return constructedVersion.split(".").map((v) => Number(v));
};

export const compareVersion = (a, b) => {
  const versionA = getVersion(a) || [],
    versionB = getVersion(b) || [];
  const versionDepth = Math.max(versionA.length, versionB.length);
  for (let i = 0; i < versionDepth; i++) {
    const firstSplit = parseInt(versionA[i] || 0, 10);
    const secondSplit = parseInt(versionB[i] || 0, 10);
    if (firstSplit > secondSplit) {
      return -1;
    }
    if (secondSplit > firstSplit) {
      return 1;
    }
  }
  return 0;
};
