import React from "react"
import Card from "react-bootstrap/Card"
import PropTypes from 'prop-types';


const BottomCard = ({ header = "Header", text = "Text", link = "http://localhost.com", icon="" }) => {
  return (
    <>
      <Card>
        <Card.Header>
          { header }
        </Card.Header>
        <Card.Body>
          <Card.Text>
            { text }
          </Card.Text>
          Learn <Card.Link href={ link } target="blank" style={{ color: "#5AB5C9" }}>More {icon} </Card.Link>
        </Card.Body>
      </Card>
    </>
  )
}

BottomCard.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
}

export default BottomCard
