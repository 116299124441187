import React from "react";
import _ from "lodash";

import Card from "../common/Card";

const CardGrid = ({ data }) => {
  const splittedData = _.chunk(data, 3);
  const rows = splittedData.map(item => {
    const items = item.map(item => (
      <Card
        key={item.tags}
        title={item.tags}
        description={item.description}
        slug="plugins"
      />
    ));
    return <div className="row">{items}</div>;
  });
  return rows;
};

export default CardGrid;
