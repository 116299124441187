import React, { Component } from "react";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import "./header.css";
import Logo from "../../assets/images/logo.svg";
import { fetchAllProducts } from "../../actions/Product";
import ProductDropdown from "./productDropdown";
import ProductDropdownMobile from "./productDropdownMobile";
import UserProfile from "./userProfile";
import UserProfileMobile from "./userProfileMobile";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      stepIndex: -1,
    };
  }

  nav = React.createRef();

  handleSearchQueryChange = (e) => {
    const searchQuery = e.target.value;
    this.setState({ searchQuery });
    if (e.keyCode === 13) {
      this.props.history.push(`/search?q=${searchQuery}`);
    }
  };

  render() {
    const newStyle = {
      position: "relative",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 999,
      transition: "position .8s linear",
    };

    if (
      ["/playbook-explorer", "/playbook-explorer/"].includes(
        this.props.location.pathname
      )
    ) {
      return null;
    }
    return (
      <>
        <div className="sticky-top">
          <Navbar bg="light" className="navbar navbar-light d-none d-md-flex">
            <Link to="/">
              <Navbar.Brand
                className="mr-auto mb-auto"
                style={{ marginLeft: "5.5rem" /* "55px" */ }}
              >
                <img src={Logo} className="logo" alt="" />
              </Navbar.Brand>
            </Link>
            <Nav
              id="external-links"
              className="navbar-nav ml-auto large mb-2 mb-md-0"
              style={{ marginRight: "3.5rem" /* "35px" */ }}
            >
              <Nav.Item className="nav-item">
                <Nav.Link
                  className="nav-link py-1"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="/playbook-explorer"
                  style={{ color: "#000000", fontSize: "1.3rem" /* "small" */ }}
                >
                  Playbook Explorer
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link
                  className="nav-link py-1"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://servicedesk.logpoint.com/hc/en-us"
                  style={{ color: "#000000", fontSize: "1.3rem" /* "small" */ }}
                >
                  Help Center
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="nav-link py-1"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://servicedesk.logpoint.com/hc/en-us/categories/200857725-Partner-Portal"
                  style={{ color: "#000000", fontSize: "1.3rem" /* "small" */ }}
                >
                  Partner Portal
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="nav-link py-1"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://servicedesk.logpoint.com/hc/en-us/categories/200824775-Products"
                  style={{ color: "#000000", fontSize: "1.3rem" /* "small" */ }}
                >
                  Release Notes
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar>

          <Navbar
            className="navbar navbar-light bg-light dropdown mr-auto d-none d-md-flex"
            expand="md"
          >
            <div className="container-fluid product-navbar">
              <ProductDropdown onStepClick />
              <UserProfile />
            </div>
          </Navbar>

          {/* Mobile view menu */}
          <Navbar
            collapseOnSelect
            expand="md"
            bg="light"
            className="d-flex d-md-none"
          >
            <Navbar.Brand
              className="mr-auto mb-auto"
              style={{ marginLeft: "1rem" /* "10px" */ }}
              href="/"
            >
              <img src={Logo} className="logo" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              style={{ marginRight: "1rem" /* "10px" */ }}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <UserProfileMobile />
              <ProductDropdownMobile />
              <Nav style={{ paddingLeft: "3rem" /* "30px" */ }}>
                <Nav.Link
                  rel="noopener noreferrer"
                  className="header-links"
                  target="_blank"
                  href="https://servicedesk.logpoint.com/hc/en-us"
                >
                  Help Center
                </Nav.Link>
                <Nav.Link
                  rel="noopener noreferrer"
                  className="header-links"
                  target="_blank"
                  href="https://servicedesk.logpoint.com/hc/en-us/categories/200857725-Partner-Portal"
                >
                  Partner Portal
                </Nav.Link>
                <Nav.Link
                  rel="noopener noreferrer"
                  className="header-links"
                  target="_blank"
                  href="https://servicedesk.logpoint.com/hc/en-us/categories/200824775-Products"
                >
                  Release Notes
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  allProducts: state.products.all,
  allManuals: state.products.allManuals,
  allRawManuals: state.products.allRawManuals,
  allProductsAndCategories: state.products.allManualsTree,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchAllProducts
  })
)(Header);
