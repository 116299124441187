
import React, { Component } from "react";
import { connect } from "react-redux";

import keysIn from "lodash/keysIn";
import Collapsible from 'react-collapsible';

import { fetchAllProducts } from "../../actions/Product";

import { ProductMenu } from './productMenu'
import './productMenu.css';

class ProductDropdownMobile extends Component {
    constructor(props) {
        super(props);
        this.productMenu = React.createRef();
    }



    render() {
        const { allRawManuals, allProductsAndCategories } = this.props;
        return (
            <div className="productMenuSm">
                {
                    keysIn(allProductsAndCategories).map((product, index) => (
                        <Collapsible key={index} accordionPosition="1" trigger={product}>
                            <ProductMenu key={product} index={`product${index}`} {...this.props} allManuals={allRawManuals} product={product} />
                        </Collapsible>
                    ))

                }
            </div>
        )

    }
}

const mapStateToProps = state => ({
    allRawManuals: state.products.allRawManuals,
    allProductsAndCategories: state.products.allManualsTree
});

export default connect(
    mapStateToProps,
    { fetchAllProducts }
)(ProductDropdownMobile);
