import { FETCH_RAW_MANUALS, FETCH_ALL_MANUALS } from '../actions/Product';

const initialState = {
    allRawManuals: null,
    allManuals: null,
    allManualsTree: null

}

const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RAW_MANUALS: {
            return {
                ...state,
                allRawManuals: action.payload
            }
        }
        case FETCH_ALL_MANUALS: {
            return {
                ...state,
                allManuals: action.payload.res,
                allManualsTree: action.payload,
                allProductsAndCategories: action.payload
            }
        }
        default:
            return state;
    }
}

export default ProductReducer;