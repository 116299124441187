import React from 'react';
import { useSelector } from 'react-redux';

import SERVER_URL from '../../constants/app'
import 'bootstrap/dist/css/bootstrap.min.css';

import './userProfile.css'

const logoutUrl = SERVER_URL + '/accounts/logout';
const loginUrl = SERVER_URL + '/accounts/login';

const UserProfileMobile = (props) => {
    const user = useSelector(state => state.user.userDetail)

    if (!user || user.is_guest){
        return (
            <ul className="userProfileContainer">
                <li className="userLogoutSm">
                    <a href={loginUrl}>
                        Sign In
                    </a>
                </li>
            </ul>
        )
    }
  
    return (
        <ul className="userProfileContainer">
            <div className="userProfileSm">
                <li>
                    {user.first_name}&nbsp;{user.last_name}
                </li>
                <li>
                    {user.email}
                </li>
            </div>

            <li className="userLogoutSm">
                <a href={logoutUrl}>
                    Sign Out
                </a>
            </li>
        </ul>
    )
}

export default UserProfileMobile;