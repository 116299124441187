import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import Store from "./store/index";

import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import './assets/css/common.css'
import './assets/css/main.css'
import './assets/css/modal-content.css'
import './assets/css/custom.css'
import './assets/css/custom-layout.css'

import './assets/css/icons.css'

import './assets/css/responsive-xl.css'

ReactDOM.render(
    <Provider store={Store}>
        <App />
    </Provider>,
    document.getElementById('root'));

serviceWorker.unregister();
