import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Card = ({ title, description, slug, link }) => {
  return (
    <div className="col-md-4 col-sm-4">
      <div className="features-content">
        <span className="box1">
          <span aria-hidden="true" className="icon-dial" />
        </span>
        <h3>
          {link ? (
            <a href={link}>{title}</a>
          ) : (
              <Link to={`/${slug}/${title}`}>{title}</Link>
            )}
        </h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

Card.defaultProps = {
  title: "Sample title",
  description: "Sample Desc"
};

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default Card;
