import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardGrid from '../components/common/CardGrid';


import { fetchAllProducts } from '../actions/Product';
import apiCall from '../utils/apiCall';

class Categories extends Component {
    state = {
        categories: []
    }

    componentWillMount() {
        const { product } = this.props.match.params;
        let url = '/api/v2/allcategory';
        let queryString = {
            project_in: product
        }
        apiCall(url, queryString)
            .then(res =>
                this.setState({ categories: res })
            );

    }
    render() {
        const data = { data: this.state.categories }
        const { product } = this.props.match.params;
        const slug = `products/${product}`
        return (
            <section className="features">
                <div className="container">
                    {/* <Header title="Products" subtitle="lorem" /> */}
                    <CardGrid data={data} titleKey='category' slug={slug} />
                </div>
            </section>
        )
    }

}

const mapStateToProps = state => ({
    allProducts: state.products.all
})


export default connect(mapStateToProps, { fetchAllProducts })(Categories);