import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import pullAll from "lodash/pullAll";
import uniqueId from 'lodash/uniqueId'
import Loader from "../common/Loading";
import ResultItem from "./ResultItem";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./result.css";

class Results extends Component { 
  titleCase = str => {
    var splitStr = str
      .toLowerCase()
      .split("-")
      .map(el => el.charAt(0).toUpperCase() + el.slice(1));
    return splitStr.join(" ");
  };


  render() {
    const { results, fetching } = this.props;

    if (!results || fetching) {
      return (
        <div className="container-fluid">
          <Loader type="ThreeDots" color="#F0AD4E" />
        </div>
      );
    }

    if (results.length <= 0) {
      return (
        <div className="container-fluid">
          <div
            className="col-md-12 col-centered"
            style={{ float: "none", margin: "0 auto", paddingTop: 70 }}
          >
            <h5 style={{ textAlign: "center" }}>No results found</h5>
          </div>
        </div>
      );
    }
    let clonedResults = [...results];
    let clonedResultsCopy = [...results];

    let filteredResults = [];
    clonedResults.forEach(result => {
      const availableVersions = clonedResultsCopy.filter(
        item => item.title === result.title && result.project === item.project
      );
      if (availableVersions.length > 0) {
        filteredResults.push(availableVersions);
        pullAll(clonedResultsCopy, availableVersions);
      }
    });

    const resultList = filteredResults.map((item, index) => <ResultItem id={`searchresult-${index}`} key={uniqueId()} data={item} />);
    return (

      
      <div className="container-fluid" id="search-results">
        {resultList}
      </div>
    );
  }
}



export default connect(
  )(withRouter(Results))
