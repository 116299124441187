import React from "react";
import {
  Card,
  Collapse,
  Divider,
  Drawer,
  Tabs,
  PageHeader,
  Radio,
  Typography,
  List,
  Input,
  Progress,
  Tag,
  Space,
  Select,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Fuse from "fuse.js";
import {
  fetchPlaybookIntegrations,
  setActivePlaybook,
  addUpdateSelectedIntegration,
} from "../../actions/playbook";
import { isPlayable, isPlayBookGeneric } from "./utils";
import PlaybookItem from "./PlaybookItem";
import {
  CHECKED_COLOR,
  ENTITY_PLAYBOOK,
  ENTITY_PRODUCT,
} from "./stringConstants";
import "./index.css";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

const Playbook = () => {
  const dispatch = useDispatch();
  const soarData = useSelector((state) => state.playBooks.playBookIntegrations);
  const isPlaybookFetching = useSelector(
    (state) => state.playBooks.isPlaybookFetching
  );
  const soarDataMap = useSelector(
    (state) => state.playBooks.playBookIntegrationsMap
  );
  const currSelectedPlayBookId = useSelector(
    (state) => state.playBooks.currSelectedPlayBook
  );
  const selectedIntegrations = useSelector(
    (state) => state.playBooks.selectedIntegrations
  );
  const [contentList, setContentList] = React.useState("all");
  const [activeTab, setActiveTab] = React.useState("1");
  const [searchText, setSearchText] = React.useState("");
  const [selectedUseCase, setUseCase] = React.useState("all");
  const [selectedType, setType] = React.useState("all");

  const fuseOptions = {
    keys: [
      "entityName",
      "dependencies.entityName",
      "dependencies.dependencies.entityName",
      "dependencies.dependencies.dependencies.entityName",
    ],
    includeMatches: true,
    threshold: 0.4,
  };

  const activePlaybook = soarData.find(
    (item) => item.entityId === currSelectedPlayBookId
  );

  const [isDrawerOpen, setDrawerOpen] = React.useState(true);

  React.useEffect(() => {
    document.title = "Playbook Explorer";
    dispatch(fetchPlaybookIntegrations());
  }, []);

  React.useEffect(() => {
    setType("all");
  }, [activeTab]);

  const onIntegrationSelect = (item) => {
    dispatch(setActivePlaybook(item.entityId));
    setDrawerOpen(true);
  };

  const onSwitchChecked = (entityId, checked) => {
    dispatch(addUpdateSelectedIntegration(entityId, checked));
  };

  const getIntegrationItems = (item, list, uniqueIds, category) => {
    if (
      item.dependencies &&
      item.dependencies.length > 0 &&
      item.entityType !== ENTITY_PRODUCT
    ) {
      item.dependencies.forEach((subItem) => {
        getIntegrationItems(subItem, list, uniqueIds, category);
      });
    } else if (
      item.entityType !== ENTITY_PLAYBOOK &&
      !uniqueIds.has(item.entityId)
    ) {
      list.push({ ...item, category });
      uniqueIds.add(item.entityId);
    }
  };

  const renderPlayBookList = () => {
    const listItems = getPlaybookList();
    const fuse = new Fuse(listItems, fuseOptions);

    const data = searchText
      ? fuse.search(searchText).map((item) => item.item)
      : listItems;

    return data
      .filter((item) =>
        contentList === "all"
          ? true
          : selectedIntegrations.includes(item.entityId)
      )
      .filter((item) =>
        selectedType === "all" ? true : item.category === selectedType
      );
  };

  const getPlaybookList = () => {
    const listItems = [];
    const uniqueIds = new Set();
    soarData
      .filter((dep) => dep.dependencies.length > 0)
      .forEach((item) => {
        getIntegrationItems(
          item,
          listItems,
          uniqueIds,
          item.entityData.category
        );
      });

    return listItems;
  };

  const getPanelItems = (playbook, list, category, uniqueIds) => {
    if (
      playbook.entityType !== ENTITY_PRODUCT &&
      playbook.dependencies &&
      playbook.dependencies.length > 0
    ) {
      playbook.dependencies.forEach((dep) =>
        getPanelItems(dep, list, category, uniqueIds)
      );
    } else {
      if (
        playbook.entityType !== ENTITY_PLAYBOOK &&
        !uniqueIds.has(playbook.entityId)
      ) {
        list.push(playbook);
        uniqueIds.add(playbook.entityId);
      }
    }
  };

  const renderPanelItems = (playbook, category) => {
    const playbookList = [];
    const uniqueIds = new Set();
    getPanelItems(
      playbook,
      playbookList,
      playbook.entityData.category || category,
      uniqueIds
    );
    return playbookList.map((item, index) => (
      <PlaybookItem
        key={`${item.entityId}==${index}`}
        item={{ ...item, category }}
        onSwitchChecked={onSwitchChecked}
        isChecked={selectedIntegrations.includes(item.entityId)}
      />
    ));
  };

  const addPanelsAndItemsToList = (
    playbook,
    panels,
    category,
    index,
    isProgress,
    totalPlaybooks,
    selectedPlaybooks,
    insertedPanels
  ) => {
    if (playbook.dependencies && playbook.dependencies.length > 0) {
      const dependencies = playbook.dependencies.reduce((acc, curr) => {
        if (curr.entityType === ENTITY_PLAYBOOK) {
          acc.push(...curr.dependencies.map((dep) => dep.entityId));
        } else {
          acc.push(curr.entityId);
        }
        return acc;
      }, []);
      const currSelectedDependencies = selectedIntegrations.filter((int) =>
        dependencies.includes(int)
      );

      const steps = isPlayBookGeneric(playbook.entityName)
        ? playbook.minumumIntegration || 1
        : playbook.dependencies.length;
      const completedSteps = currSelectedDependencies.length || 0;

      if (isProgress) {
        if (!insertedPanels.has(playbook.entityName)) {
        Array.from({ length: steps }).forEach(() => totalPlaybooks.push(1));
        Array.from({ length: completedSteps >= steps ? steps : completedSteps }).forEach(() =>
          selectedPlaybooks.push(1)
        );
        insertedPanels.add(playbook.entityName);
        }
      } else {
        if (!insertedPanels.has(playbook.entityName)) {
          panels.push(
            <Panel
              header={
                <span>
                  {playbook.entityName}{" "}
                  {isPlayable(
                    playbook.entityId,
                    selectedIntegrations,
                    soarDataMap
                  ) && <CheckCircleOutlined style={{ color: CHECKED_COLOR }} />}
                </span>
              }
              key={`item-${index}`}
              extra={renderProgress(steps, completedSteps)}
            >
              {renderPanelItems(
                playbook,
                playbook.entityData.category || category
              )}
            </Panel>
          );
          insertedPanels.add(playbook.entityName);
        }
      }
    }
  };

  const computePlaybookPanels = (playbook, category, isProgress = false) => {
    const panels = [];
    const insertedPanels = new Set();
    const totalPlaybooks = [];
    const selectedPlaybooks = [];

    const playbooks = playbook.dependencies.filter(
      (dep) => dep.entityType !== ENTITY_PRODUCT
    );

    for (let i = 0; i < playbooks.length; i++) {
      const currPlaybook = playbooks[i];
      if (
        currPlaybook.dependencies.some(
          (dep) => dep.entityType === ENTITY_PLAYBOOK
        )
      ) {
        // Nested playbook
        currPlaybook.dependencies
          .filter((dep) => dep.entityType === ENTITY_PLAYBOOK)
          .forEach((dep, index) =>
            addPanelsAndItemsToList(
              dep,
              panels,
              category,
              `${i}-${index}`,
              isProgress,
              totalPlaybooks,
              selectedPlaybooks,
              insertedPanels
            )
          );
        const nonNestedDeps = currPlaybook.dependencies.filter(
          (dep) => dep.entityType !== ENTITY_PLAYBOOK
        );
        if (nonNestedDeps.length > 0) {
          addPanelsAndItemsToList(
            { ...currPlaybook, dependencies: nonNestedDeps },
            panels,
            category,
            i,
            isProgress,
            totalPlaybooks,
            selectedPlaybooks,
            insertedPanels
          );
        }
      } else {
        addPanelsAndItemsToList(
          currPlaybook,
          panels,
          category,
          i,
          isProgress,
          totalPlaybooks,
          selectedPlaybooks,
          insertedPanels
        );
      }
    }

    if (isProgress) {
      return {
        total: totalPlaybooks.length,
        selected: selectedPlaybooks.length,
      };
    }

    return panels;
  };

  const getTopLevelProgress = (playbook) => {
    const topLevelProducts = playbook.dependencies.filter(
      (dep) => dep.entityType === ENTITY_PRODUCT
    );

    return {
      total: isPlayBookGeneric(playbook.entityId)
        ? playbook.minumumIntegration || topLevelProducts.length
        : topLevelProducts.length,
      selected:
        selectedIntegrations.filter((int) =>
          playbook.dependencies
            .filter((dep) => dep.entityType === ENTITY_PRODUCT)
            .map((dep) => dep.entityId)
            .includes(int)
        ).length || 0,
    };
  };

  const getCollapsibileData = (playbook, category, name) => {
    if (
      playbook.dependencies.length === 1 &&
      playbook.dependencies[0].entityName.includes("SubPlaybook")
    ) {
      return getCollapsibileData(
        playbook.dependencies[0],
        playbook.dependencies[0].entityData.category,
        playbook.entityName
      );
    }
    const topLevelProducts = playbook.dependencies.filter(
      (dep) => dep.entityType === ENTITY_PRODUCT
    );

    const topLevelProgress = getTopLevelProgress(playbook);

    return (
      <Collapse accordion defaultActiveKey={["item-0"]}>
        {computePlaybookPanels(playbook, category)}
        {topLevelProducts.length > 0 && (
          <Panel
            header={
              <span>
                {name}{" "}
                {isPlayable(
                  playbook.entityId,
                  selectedIntegrations,
                  soarDataMap,
                  isTopLevelWithSubPlaybook(playbook)
                ) && <CheckCircleOutlined style={{ color: CHECKED_COLOR }} />}
              </span>
            }
            extra={renderProgress(
              topLevelProgress.total,
              topLevelProgress.selected
            )}
          >
            {topLevelProducts.map((product, index) => (
              <PlaybookItem
                key={`${product.entityId}--${index}`}
                item={{
                  ...product,
                  category: product.entityData.category || category,
                }}
                onSwitchChecked={onSwitchChecked}
                isChecked={selectedIntegrations.includes(product.entityId)}
              />
            ))}
          </Panel>
        )}
      </Collapse>
    );
  };

  const renderProgress = (steps, inputCompletedSteps) => {
    const completedSteps =
      inputCompletedSteps >= steps ? steps : inputCompletedSteps;

    const completedPercent = Math.floor((completedSteps / steps) * 100);

    return (
      <Progress
        percent={completedPercent}
        steps={steps}
        format={() => `${completedSteps} / ${steps}`}
        strokeColor={completedPercent === 100 ? CHECKED_COLOR : ""}
      />
    );
  };

  const getListData = () => {
    const fuse = new Fuse(soarData, fuseOptions);

    const data = searchText
      ? fuse.search(searchText).map((item) => item.item)
      : soarData;
    return data
      .filter((dep) => dep.dependencies.length > 0)
      .filter((dep) =>
        selectedUseCase === "all" ? true : dep.useCase === selectedUseCase
      )
      .filter((dep) =>
        selectedType === "all" ? true : dep.entityData.category === selectedType
      )
      .filter((dep) =>
        contentList === "all"
          ? true
          : isPlayable(
              getEntityId(dep),
              selectedIntegrations,
              soarDataMap,
              isTopLevelWithSubPlaybook(dep)
            )
      );
  };

  const getProgress = (item) => {
    if (
      item.dependencies.length === 1 &&
      item.dependencies[0].entityName.includes("SubPlaybook")
    ) {
      return getProgress(item.dependencies[0]);
    }
    const depData = computePlaybookPanels(item, undefined, true);

    const topLevelProgress = getTopLevelProgress(item);

    return renderProgress(
      depData.total + topLevelProgress.total,
      depData.selected + topLevelProgress.selected
    );
  };

  const renderUseCasesDrop = () => {
    const data = soarData
      .reduce((acc, curr) => {
        if (!acc.includes(curr.useCase)) {
          acc.push(curr.useCase);
        }
        return acc;
      }, [])
      .map((item, index) => (
        <Option key={index} value={item}>
          {item}
        </Option>
      ));

    data.unshift(<Option value="all">All Use-Cases</Option>);

    return data;
  };

  const renderTypeDrop = () => {
    let data = soarData.reduce((acc, curr) => {
      if (!acc.includes(curr.entityData.category)) {
        acc.push(curr.entityData.category);
      }
      return acc;
    }, []);

    if (activeTab === "2") {
      data = getPlaybookList().reduce((acc, curr) => {
        if (!acc.includes(curr.category)) {
          acc.push(curr.category);
        }
        return acc;
      }, []);
    }

    const options = data.map((item, index) => (
      <Option key={index} value={item}>
        {item}
      </Option>
    ));

    options.unshift(<Option value="all">All Types</Option>);

    return options;
  };

  const getEntityId = (playbook) => {
    return playbook.dependencies.length === 1 &&
      playbook.dependencies[0].entityName.includes("SubPlaybook")
      ? playbook.dependencies[0].entityId
      : playbook.entityId;
  };


  const isTopLevelWithSubPlaybook = (playbook) => {
    return playbook.dependencies.some(dep => dep.entityType === ENTITY_PLAYBOOK);
  }

  return (
    <div className="playbook-app">
      <PageHeader
        className="site-page-header-responsive"
        title="Logpoint Playbook Explorer"
        extra={[
          <Radio.Group
            key={"radio-group"}
            buttonStyle="solid"
            value={contentList}
            onChange={(e) => setContentList(e.target.value)}
          >
            <Radio.Button value="all">All content</Radio.Button>
            <Radio.Button value="my">My content</Radio.Button>
          </Radio.Group>,
        ]}
        footer={
          <>
            <div className="input-container">
              <Input
                id="search-input"
                placeholder={
                  activeTab === "1"
                    ? "Search playbooks"
                    : "Search integrations or provider"
                }
                onChange={(e) => setSearchText(e.target.value)}
              />
              {activeTab === "1" && (
                <Select
                  value={selectedUseCase}
                  bordered={false}
                  onSelect={(key) => setUseCase(key)}
                >
                  {renderUseCasesDrop()}
                </Select>
              )}
              <Select
                value={selectedType}
                bordered={false}
                onSelect={(key) => setType(key)}
              >
                {renderTypeDrop()}
              </Select>
            </div>
            <Tabs defaultActiveKey="1" onChange={setActiveTab}>
              <TabPane
                tab="Playbooks"
                key="1"
                style={{ backgroundColor: "#F0F2F5" }}
              >
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 3,
                  }}
                  loading={isPlaybookFetching}
                  dataSource={getListData()}
                  renderItem={(item, index) => (
                    <List.Item key={`${item.entityName}-${index}`}>
                      <Card
                        style={{ height: 260 }}
                        onClick={() => onIntegrationSelect(item)}
                      >
                        <Space direction="vertical">
                          <Space align="center">
                            <Title level={5} style={{ marginBottom: "0px" }}>
                              {item.entityName}
                            </Title>
                            {isPlayable(
                              getEntityId(item),
                              selectedIntegrations,
                              soarDataMap,
                              isTopLevelWithSubPlaybook(item)
                            ) && (
                              <Tag
                                icon={<CheckCircleOutlined />}
                                color="success"
                              >
                                Enabled
                              </Tag>
                            )}
                          </Space>
                          <Space size="4px" split={<Divider type="vertical" />}>
                            <Text type="secondary" style={{ fontSize: "12px" }}>
                              Use case: {item.useCase}
                            </Text>
                            <Text type="secondary" style={{ fontSize: "12px" }}>
                              Type: {item.entityData.category}
                            </Text>
                          </Space>
                          <Space>
                            <Text type="secondary">Playbook Integrations:</Text>
                            {getProgress(item)}
                          </Space>
                          <Paragraph ellipsis={{ rows: 3 }}>
                            {item.detail}
                          </Paragraph>
                        </Space>
                      </Card>
                    </List.Item>
                  )}
                />
              </TabPane>
              <TabPane tab="Playbooks Integrations" key="2">
                <List
                  dataSource={renderPlayBookList()}
                  loading={isPlaybookFetching}
                  renderItem={(item, index) => (
                    <PlaybookItem
                      key={`${item.entityName}--${index}`}
                      item={item}
                      index={index}
                      onSwitchChecked={onSwitchChecked}
                      showDivider={false}
                      isChecked={selectedIntegrations.includes(item.entityId)}
                      isList
                    />
                  )}
                />
              </TabPane>
            </Tabs>
          </>
        }
      />
      {activePlaybook && (
        <Drawer
          title={
            <span>
              {activePlaybook.entityName}{" "}
              {isPlayable(
                getEntityId(activePlaybook),
                selectedIntegrations,
                soarDataMap,
                isTopLevelWithSubPlaybook(activePlaybook)
              ) && (
                <CheckCircleOutlined
                  checked={true}
                  style={{ color: CHECKED_COLOR }}
                />
              )}
            </span>
          }
          placement="right"
          size={"large"}
          onClose={() => setDrawerOpen(false)}
          visible={isDrawerOpen}
        >
          <p>{activePlaybook.detail}</p>
          <Divider />
          <Title level={5}>Playbook Integration Dependencies</Title>

          {getCollapsibileData(
            activePlaybook,
            activePlaybook.entityData.category,
            activePlaybook.entityName
          )}
        </Drawer>
      )}
    </div>
  );
};

export default Playbook;
