import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import Loader from "../components/common/Loading";
import apiCall from "../utils/apiCall";

const Soar = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [allGuides, setAllGuides] = useState([]);
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const product = params.get("p");
  const page = params.get("page");

  const pageGuides =
    allGuides && allGuides[product] && allGuides[product][page];

  React.useEffect(() => {
    apiCall("/api/v2/soar_guides")
      .then((guides) => {
        setAllGuides(guides);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="container-fluid">
        <Loader type="ThreeDots" color="#F0AD4E" />
      </div>
    );
  }

  const selectOptions = pageGuides
    .reduce((acc, guide) => {
      const tags = acc;
      if (guide.tags) {
        acc.push(...guide.tags);
      }
      const uniqueTags = new Set(tags);
      return Array.from(uniqueTags);
    }, [])
    .map((tag) => ({ label: tag, value: tag }));

  return (
    <div className="container-fluid">
      {pageGuides && pageGuides.length > 0 ? (
        <div style={{ margin: "3rem 6rem" }}>
          <div className="row" style={{ marginBottom: "15px" }}>
            {selectOptions && selectOptions.length > 0 && (
              <div className="col-3 col-md-4 col-sm-6 col-xs-12 pl-0">
                <Select
                  selectedOption={selectedCategory}
                  options={selectOptions}
                  placeholder={"Select Category"}
                  onChange={(selected) => {
                    setSelectedCategory(selected?.value || "");
                  }}
                  isClearable
                />
              </div>
            )}
            <div className="mb-4">
              <input
                type="text"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                placeholder={"Search by name"}
                className="playbook-search-menu"
                value={searchText}
              />
            </div>
          </div>
          <div className="row">
            <Table responsive>
              <thead>
                <tr>
                  <th style={{backgroundColor: "#f8f9fa"}}>{page}</th>
                </tr>
              </thead>
              <tbody>
                {pageGuides
                  .filter((guide) =>
                    selectedCategory
                      ? guide.tags.includes(selectedCategory)
                      : true
                  )
                  .filter((guide) =>
                    guide.display_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                  .sort((a, b) => {
                    let aCase = a.display_name.toLowerCase(),
                      bCase = b.display_name.toLowerCase();
                    if (aCase < bCase)
                      //sort string ascending
                      return -1;
                    if (aCase > bCase) return 1;
                    return 0;
                  })
                  .map((guide) => (
                    <tr key={`soar-${guide.slug}`}>
                      <td
                        style={{ textAlign: "justify", paddingRight: "3rem" }}
                      >
                        <a
                          className="manual-info"
                          href={`${guide.canonical_url}`}
                        >
                          {guide.display_name}
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div
          className="col-md-12 col-centered"
          style={{ float: "none", margin: "0 auto", paddingTop: 70 }}
        >
          <h5 style={{ textAlign: "center" }}>No results found</h5>
        </div>
      )}
    </div>
  );
};

export default Soar;
