import './polyfil/object.assign.polyfil'
import React from 'react';
import Layout from './components/layout'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Index from './pages/index'
import Category from './pages/Category'
import Plugins from './pages/Plugins';
import Plugin from './pages/Plugin';
import Search from './pages/search';
import Categories from './pages/Categories';
import Soar from './pages/soar';
import Playbook from './pages/playbooks';

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/products/plugins" exact component={Plugins} />
          <Route path="/plugins/:category" component={Plugin} />
          <Route path="/products/:product" exact component={Categories} />
          <Route path="/products/:product/:category" component={Category} />
          <Route path="/search" component={Search} />
          <Route path="/soar" component={Soar} />
          <Route path="/playbook-explorer" component={Playbook} />
        </Switch>
      </Layout>

    </Router>
  );
}

export default App;
