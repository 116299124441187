import React, { Component } from "react";
import Header from "../components/common/Header";
import CardGrid from "../components/Product/CardGrid";
import axios from "axios";
import SERVER_URL from "../constants/app";

class Product extends Component {
  state = {
    pluginList: []
  };

  componentWillMount() {
    const { category } = this.props.match.params;
    axios
      .get(SERVER_URL + `/api/v2/allplugins/tags?type=${category}`, {
        withCredentials: true
      })
      .then(res => this.setState({ pluginList: res.data }));
  }
  render() {
    const { category } = this.props.match.params;
    const { pluginList } = this.state;
    return (
      <>
        <section className="features">
          <div className="container">
            <Header title={category} subtitle="" />
            <CardGrid data={pluginList} />
          </div>
        </section>
      </>
    );
  }
}

Product.propTypes = {};

export default Product;
