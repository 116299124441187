import types from "../actions/types"

const initialState = {

}


const WalkThroughReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_WALKTHROUGH_STATE:
            {
                return {
                    ...action.payload
                }
            }
        case types.UPDATE_WALKTHROUGH_STEP:
            {
                return {
                    ...action.payload
                }
            }
        default:
            return state;
    }

}

export default WalkThroughReducer;