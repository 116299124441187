import React, { Component } from "react";

import "./searchBar.css";

class SearchBar extends Component {
  render() {
    const { searchQuery, handleSearchQueryChange, handleSearch } = this.props;
    return (
      <div className="row" id={this.props.id}>
        <div className="col-md-9 col-lg-9">
          <div className="form-group searchForm has-search">
            <i className="icon-search-icon search-icon form-control-feedback"></i>
            {/* <span className="icon-user form-control-feedback"></span> */}
            <input
              type="text"
              className="col-md-11 col-lg-11 form-control searchFormControl"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => handleSearchQueryChange(e)}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && searchQuery) {
                  handleSearch(searchQuery);
                }
              }}
            />
          </div>
        </div>

        <div className="col-md-3 col-lg-3">
          <input
            type="submit"
            className="btn btn-block searchFormSubmit"
            value="Search"
            onClick={() => handleSearch(searchQuery)}
          />
        </div>
      </div>
    );
  }
}

export default SearchBar;
