import React, { Component } from "react";
import axios from "axios";
import keysIn from "lodash/keysIn";
import uniqBy from "lodash/uniqBy";
import { connect } from "react-redux";
import { fetchAllProducts } from "../actions/Product";
import Results from "../components/search/Results";
import SearchBar from "../components/search/SearchBar";
import SelectDropdown from "../components/search/SelectDropdown";
import SERVER_URL from "../constants/app";
import { DEFAULT_CATEGORY } from "../constants/constant";

import "./search.css";
class Search extends Component {
  state = {
    searchQuery: "",
    results: null,
    selectedProjectOption: "",
    productsIn: [],
    selectedCategoryOption: "",
    MannualCategoryList: [],
    fetching: false,
  };

  handleSearch = (query) => {
    let { searchQuery, selectedProjectOption, selectedCategoryOption } =
      this.state;

    if (query) {
      searchQuery = query;
    }

    this.setState({ fetching: true });
    axios
      .get(
        SERVER_URL +
          `/api/v2/docsearch/?lang=en&scope=global&q=${searchQuery}${
            selectedProjectOption !== null && selectedProjectOption.value
              ? "&project=" + selectedProjectOption.value
              : ""
          }${
            selectedCategoryOption !== null && selectedCategoryOption.value
              ? "&mannual_category=" + selectedCategoryOption.value
              : ""
          }`
      )
      .then((res) =>
        this.setState({ results: res.data.results, fetching: false })
      );
  };

  handleSearchQueryChange = (e) => {
    this.props.history.push(`/search?q=${e.target.value}`);
    this.setState({ searchQuery: e.target.value });
  };

  handleSelectedProjectChange = (selectedProjectOption) => {
    let selectedProductCategories = this.getManualCategoryForSelectedProduct(
      selectedProjectOption
    );
    if (selectedProductCategories.length > 0) {
      selectedProductCategories =
        selectedProductCategories[0]["value"] !== DEFAULT_CATEGORY
          ? selectedProductCategories
          : [];
      this.setState({
        MannualCategoryList: uniqBy(selectedProductCategories, "value"),
        selectedProjectOption,
        selectedCategoryOption: "",
      }, () => {
        const params = new URLSearchParams(this.props.location.search);
        const searchQuery = params.get("q");
        if(searchQuery){
          this.handleSearch(searchQuery)
        }
      });
    } else {
      this.setState({
        MannualCategoryList: [],
        selectedProjectOption,
        selectedCategoryOption: "",
      });
    }
  };

  // TO-DO
  // componentDidUpdate() {
  //   const params = new URLSearchParams(this.props.location.search);
  //   const searchQuery = params.get("q");
  //   this.handleSearch(searchQuery);
  // }

  getManualCategoryForSelectedProduct = (selectedProduct) => {
    const { allProductsAndCategories } = this.props;
    if (!selectedProduct) {
      return [{ label: "", value: "" }];
    }

    return keysIn(allProductsAndCategories[selectedProduct.label]).map(
      (productManual) => {
        return {
          value: productManual,
          label: productManual,
        };
      }
    );
  };

  handleSelectedCategoryChange = (selectedCategoryOption) => {
    this.setState({ selectedCategoryOption }, () => {
      const params = new URLSearchParams(this.props.location.search);
        const searchQuery = params.get("q");
        if(searchQuery){
          this.handleSearch(searchQuery)
        }
    });
  };

  componentWillMount() {
    const params = new URLSearchParams(this.props.location.search);
    const searchQuery = params.get("q");
    this.setState({ searchQuery }, () => this.handleSearch());
  }
  render() {
    const {
      results,
      searchQuery,
      selectedProjectOption,
      selectedCategoryOption,
      MannualCategoryList,
      fetching,
    } = this.state;

    const { allProductsAndCategories } = this.props;

    let productsList = [];
    if (allProductsAndCategories) {
      productsList = keysIn(allProductsAndCategories).map((elem) => {
        return {
          value: elem,
          label: elem,
        };
      });
    }
    return (
      <>
        <div className="container-fluid">
          <div
            id="narrowsearch"
            className="row search-bar"
          >
            <div
              className="col-md-3 col-lg-3"
              // style={{ marginBottom: "16px", top: "2px", zIndex: "10" }}
              style={{ marginBottom: "1.6rem", top: "0.2rem", zIndex: "10" }}
            >
              <SelectDropdown
                id="products-dropdown"
                placeHolder="All Products"
                selectedOption={selectedProjectOption}
                onChange={this.handleSelectedProjectChange}
                options={productsList}
              />
            </div>
            <div
              className="col-md-3 col-lg-3"
              // style={{ marginBottom: "16px", top: "2px", zIndex: "5" }}
              style={{ marginBottom: "1.6rem", top: "0.2rem", zIndex: "5" }}
            >
              <SelectDropdown
                id="guides-dropdown"
                placeHolder="All Sections"
                selectedOption={selectedCategoryOption}
                onChange={this.handleSelectedCategoryChange}
                options={MannualCategoryList}
                isDisabled={MannualCategoryList.length === 0}
              />
            </div>
            <div
              className="col-sm-6 col-lg-6"
              // style={{ marginBottom: "16px" }}
              style={{ marginBottom: "1.6rem" }}
            >
              <SearchBar
                id="searchpage-query"
                searchQuery={searchQuery}
                handleSearchQueryChange={this.handleSearchQueryChange}
                handleSearch={this.handleSearch}
              />
            </div>
          </div>
        </div>
        <Results results={results} fetching={fetching} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  allProductsAndCategories: state.products.allManualsTree
});

export default connect(mapStateToProps, { fetchAllProducts })(Search);
