const genericPlayBookList = [
    "Account Enrichment - Generic",
    "Block Account - Generic",
    "Block Domain or URL - Generic",
    "Block Hash - Generic",
    "Block Host - Generic",
    "Block IP - Generic",
    "Check Domain Reputation",
    "Check Email Reputation",
    "Check File Reputation",
    "Check IP Reputation",
    "Check URL Reputation",
    "CVE Enrichment - Generic",
    "Detonate URL - Generic",
    "IP Enrichment",
    "Search and Delete Emails",
    "UnIsolate Endpoint - Generic",
    "Terminate Spawned Processes"
    ]
    
    export const isPlayBookGeneric = (entityId) => genericPlayBookList.includes(entityId) 
    
    export const isPlayable = (playbookId, selectedIds, data, isTop) => {
    
      const playbook = data[playbookId];
    
      if (["PRODUCT", "VENDOR"].includes(playbook["type"])) {
        if (selectedIds.includes(playbookId)) {
          return true;
        } else {
          return false;
        }
      }
    
      const paths = playbook["paths"];
      if (!paths) {
        return true;
      }
    
      const isGeneric = isPlayBookGeneric(playbookId)
      let result = !isGeneric || isTop;
    
      for (const path of paths) {
        const pathResults = [];
        for (const x of path) {
          pathResults.push(isPlayable(x, selectedIds, data));
        }
        if (isGeneric && !isTop) {
          result = result || pathResults.every((elem) => elem);
        } else {
          result = result && pathResults.every((elem) => elem);
        }
      }
      return result;
    };
    
    export const getPlayablePlaybookIds = (data, selectedIds) => {
      const playablePlaybookIds = []
        for (const key in data){
          if(isPlayable(key, selectedIds, data)){
            playablePlaybookIds.push(key)
          }
        }
    
      return playablePlaybookIds
    }