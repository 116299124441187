import React, { Component } from "react";
import { connect } from 'react-redux';

class Search extends Component {
  state = {
    searchQuery: ""
  };

  handleSearchQueryChange = e => {
    this.setState({ searchQuery: e.target.value });
  };

  onClickSearch = (e) => {
    e.preventDefault();
    const trimmedSearchQuery = this.state.searchQuery.trim();
    if (!trimmedSearchQuery) {
      return false;
    }
    this.props.changeUrlForSearch(`/search?q=${trimmedSearchQuery}`);
    return false;
  }

  render() {
    const { searchQuery } = this.state;
    const mainBannerStyleSearch= {
      backgroundSize: 'cover !important',
      backgroundPosition: 'center !important',
      position: 'relative !important',
      textAlign: 'center !important',
      color: '#ffffff !important',
      // padding: '0px 0px 116px 0px !important',
      padding: '0 0 11.6rem 0 !important',
      margin: '0 !important',
  }
    return (
      <section className='main-banner'>
        <div className="container" style={{ zIndex: "100", position: "relative" }}>
          <div className="caption">
            <h2>LOGPOINT DOCUMENTATION</h2>
            <h5>Find user guides, administration guides and product documentation for Logpoint solutions​</h5>
            <form action='/search'>
              <fieldset style={{ background: "transparent" }}>
                <div id="searchholderdiv" className="row">
                <div className='col-md-2' />
                  <div className="col-md-6 col-sm-10 no-pad justify-content-end">
                    <input
                      name="q"
                      type="text"
                      className="form-control border-right"
                      style={{ height: "3.9rem"}}
                      placeholder="Search 'configure an alert in logpoint'"
                      value={searchQuery}
                      onChange={e => this.handleSearchQueryChange(e)}
                    />
                  </div>

                  <div className="col-md-2 col-sm-2 no-pad">
                    <input
                      type="submit"
                      className="btn seub-btn"
                      value="Search"
                      onClick={this.onClickSearch}
                      style={{ height: "3.9rem", lineHeight: "0.64rem", color: "black" }}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </section >
    );
  }
}



export default Search;