import React, { Component } from "react";
import { connect } from 'react-redux';
import { fetchAllProducts } from '../../actions/Product';
import { fetchUserDetail } from '../../actions/User'
import Loader from "../common/Loading";
import Header from "./header";
import Footer from "./footer";

class Index extends Component {

  componentWillMount() {
    this.props.fetchAllProducts();
    this.props.fetchUserDetail();
  }


  render() {
    if (!this.props.allManualsTree) {
      return (
        <div className="container-fluid">
          <Loader />
        </div>
      )
    }
    return (
      <>
        <Header />
        <div className="content-wrapper" id="content">{this.props.children}</div>
        <Footer />

      </>
    )
  }
}

const mapStateToProps = state => ({

  allManualsTree: state.products.allManualsTree
})


export default connect(
  mapStateToProps,
  {
    fetchAllProducts,
    fetchUserDetail,
  }
)(Index);

