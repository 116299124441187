import apiCall from "../utils/apiCall";

export const fetchPlaybookIntegrations = () => (dispatch) => {
  dispatch({
    type: "PLAYBOOKS_FETCHING",
  });

  apiCall("/api/v2/playbook_guides")
    .then((res) => {
      dispatch({
        type: "LOAD_PLAYBOOKS",
        payload: { soarData: res.soarData, soarDataMap: res.soarDataMap },
      });
    })
    .catch((err) => {
      dispatch({
        type: "PLAYBOOKS_FETCHING_FAILED",
      });
    });
};

export const setActivePlaybook = (id) => {
  return {
    type: "SET_ACTIVE_PLAYBOOK",
    payload: id,
  };
};

export const addUpdateSelectedIntegration = (uuid, checked) => {
  return {
    type: "UPDATE_SEL_INTEGRATION",
    payload: { uuid, checked },
  };
};
