import { FETCH_USER_DETAIL } from '../actions/User';

const initialState = {
    userDetail: null
}

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_DETAIL: {
            return {
                ...state,
                userDetail: action.payload[0]
            }
        }
        default:
            return state;
    }

}

export default UserReducer;