const initialState = {
  playBookIntegrations: [],
  currSelectedPlayBook: undefined,
  selectedIntegrations: [],
  playabalePlaybooks: [],
  playBookIntegrationsMap: {},
  isPlaybookFetching: false,
};

export const playBookReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PLAYBOOKS_FETCHING": {
      return { ...state, isPlaybookFetching: true };
    }
    case "PLAYBOOKS_FETCHING_FAILED": {
      return { ...state, isPlaybookFetching: false };
    }
    case "LOAD_PLAYBOOKS": {
      const { soarData, soarDataMap } = action.payload;
      return {
        ...state,
        playBookIntegrations: soarData,
        playBookIntegrationsMap: soarDataMap,
        isPlaybookFetching: false,
      };
    }
    case "SET_ACTIVE_PLAYBOOK": {
      return { ...state, currSelectedPlayBook: action.payload };
    }
    case "UPDATE_SEL_INTEGRATION": {
      const { uuid, checked } = action.payload;
      let selectedIntegrations = [];
      if (checked) {
        selectedIntegrations = [...state.selectedIntegrations, uuid];
      } else {
        selectedIntegrations = state.selectedIntegrations.filter(
          (itemUuid) => itemUuid !== uuid
        );
      }
      return { ...state, selectedIntegrations };
    }
    default:
      return state;
  }
};
