import axios from 'axios';

import SERVER_URL from '../constants/app'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

export default async (url, queryString = {}) => {
    let requestQueryString = Object.keys(queryString).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(queryString[k])
    }).join('&');

    let finalUrl = SERVER_URL + url + '?' + requestQueryString;
    return await axios.get(finalUrl)
        .then(res => res.data)
}

export const apiCallPatch = async (url, data, queryString = {}) => {
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    
    let finalUrl = SERVER_URL + url ;
    return await axios.patch(finalUrl, {...data})
        .then(res => res.data)

}




