import React from "react";
import _ from "lodash";

import Card from "./Card";

const CardGrid = ({ data, titleKey, slug }) => {
  const splittedData = _.chunk(data.data, 3);
  const rows = splittedData.map(item => {
    const items = item.map(item => (
      <Card
        key={item[titleKey]}
        title={item[titleKey]}
        // description={item.description}
        description=""
        slug={slug}
      />
    ));
    return <div className="row">{items}</div>;
  });
  return rows;
};

export default CardGrid;
