import React, { Component } from "react";
import Header from "../components/common/Header";
import CardGrid from "../components/Product/CardGrid";
import apiCall from '../utils/apiCall';

class Category extends Component {
  state = {
    subProjects: []
  };

  componentWillMount() {
    const { product, category } = this.props.match.params;

    let queryString = {
      mannual_category: category,
      project_in: product
    }

    let url = '/api/v2/allprojects'
    apiCall(url, queryString)
      .then(res => this.setState({ subProjects: res }))
  }

  render() {
    const { product } = this.props.match.params;
    const { subProjects } = this.state;
    return (
      <>
        <section className="features">
          <div className="container">
            <Header title={product} subtitle="" />
            <CardGrid data={subProjects} />
          </div>
        </section>
      </>
    );
  }
}

Category.propTypes = {};

export default Category;
