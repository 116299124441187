import React from "react";
import {useSelector} from "react-redux";
import keysIn from "lodash/keysIn";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import DropdownButton from 'react-bootstrap/DropdownButton';

import {ProductMenu} from './productMenu'
import './productMenu.css';

const ProductDropdown = props => {
    const user = useSelector(state => state.user.userDetail)
    const allRawManuals = useSelector(state => state.products.allRawManuals)
    const allProductsAndCategories = useSelector(state => state.products.allManualsTree)

    return (
        <ButtonToolbar id = 'products-header-navigation' className="mr-auto menu-sm">
        {
            keysIn(allProductsAndCategories).map((product, index) => (

                <DropdownButton
                    title={product}
                    id={`dropdown-split-${product}`}
                    key={product}
                    className="btn btn-primary product-dropdown"
                >
                    <ProductMenu
                        index={index}
                        key={product}
                        allProductsAndCategories={allProductsAndCategories}
                        allManuals={allRawManuals}
                        product={product}
                        user={user}
                    />
                </DropdownButton>
            ))
        }
        </ButtonToolbar>
    )
}

export default ProductDropdown

