import React, { Component } from 'react';
import Select from 'react-select';

class SelectDropdown extends Component {
    render() {
        const { selectedOption, onChange, options, placeHolder, isDisabled = false } = this.props;
        const customStyles = {
            control: (base, state) => ({
                ...base,
                background: "#ffffff",
                // opacity: "5px"
            }),
            menu: base => ({
                ...base,
                // override border radius to match the box
                borderRadius: 0,

                // kill the gap
                marginTop: 0
            }),
            menuList: base => ({
                ...base,
                // kill the white space on first and last option
                padding: 0,
                // opacity: "5px"
            }),
            option: base => ({
                ...base,
                background: "#f8f9fa",
                // fontSize: "14px",
                fontSize: "1.4rem",
                color: "black",
                "&:hover": {
                    background: "#7d8d9a8f",
                    color: "#ffffff"
                },
                // opacity: "5px"
            }),
            placeholder: base => ({
                ...base,
                // fontSize: "14px"
                fontSize: "1.4rem"
            })
        };

        return (
            <Select
                id={this.props.id}
                value={selectedOption}
                onChange={onChange}
                options={options}
                placeholder={placeHolder}
                isClearable={true}
                isDisabled={isDisabled}
                styles={customStyles}
            />
        );
    }
}

export default SelectDropdown;