import apiCall from '../utils/apiCall';
export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';


export const fetchUserDetail = () => dispatch => {

    apiCall("/api/v2/userdetail")
        .then(res => {
            dispatch({
                type: FETCH_USER_DETAIL,
                payload: res
            })
        })
}


