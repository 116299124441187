import React from 'react';
import { useSelector } from 'react-redux';

import SERVER_URL from '../../constants/app'
import 'bootstrap/dist/css/bootstrap.min.css';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import './userProfile.css'

const logoutUrl = SERVER_URL + '/accounts/logout';
const loginUrl = SERVER_URL + '/accounts/login';

const UserProfile = (props) => {
  const user = useSelector(state => state.user.userDetail)

  if (!user || user.is_guest) {
      return (
          <Button className="user-profile-login" href={loginUrl}>
          <i className="icon-user"></i>
              <span>Sign In</span>
          </Button>
      )
  }

  return (
    <ButtonToolbar className="user-profile">
      <OverlayTrigger
        trigger="click"
        key="bottom"
        placement="bottom"
        rootClose
        overlay={
          <Popover id={`popover-positioned-bottom`} className="popover-user-drop">
            <Popover.Title className="popover-user-dropdown" as="h3">
              <span className="userPopoverTitle">
                {user.email}
              </span>
            </Popover.Title>
            <Popover.Content className="popover-logout">
              <a className="popover-user-dropdown" href={logoutUrl}>
                Sign Out
              </a>
            </Popover.Content>
          </Popover>
        }
      >
        <Button>
          <i className="icon-user"></i>
          <span>
            {user.first_name}&nbsp;{user.last_name}
          </span>
        </Button>
      </OverlayTrigger>
    </ButtonToolbar>
  )
}

export default UserProfile;