import React, { Component } from "react";

import CardDeck from 'react-bootstrap/CardDeck';

import BottomCard from "../common/BottomCard";

import './products.css';
import SERVER_URL from "../../constants/app";

class Products extends Component {
    render() {
        return (
            <React.Fragment>
                <>
                    <CardDeck className="main-page-card-deck">
                         {/* What's new section */}
                        <BottomCard
                            header="What's New"
                            text="The latest Logpoint Platform and product versions."
                            link={`${SERVER_URL}/docs/whats-new-in-logpoint/en/latest/`}
                        />
                        {/* Logpoint platform section */}
                        <BottomCard
                            header="Logpoint Integrations"
                            text="Our platform includes 500+ out-of-the-box integrations."
                            link={`${SERVER_URL}/docs/supported-log-source/en/latest/`}
                        />
                    </CardDeck>
                </>
            </React.Fragment>
        )
    }
}

export default Products;
