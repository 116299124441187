import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';

import './versionDropdown.css';

class CustomToggle extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();

        this.props.onClick(e);
    }

    render() {
        return (
            <a href="" className="available-version-dropdown-button" onClick={this.handleClick}>
                {this.props.children}
            </a>
        );
    }
}

class CustomMenu extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);

        this.state = { value: '' };
    }

    handleChange(e) {
        this.setState({ value: e.target.value.toLowerCase().trim() });
    }

    render() {
        const {
            children,
            style,
            className,
            'aria-labelledby': labeledBy,
        } = this.props;

        const { value } = this.state;

        return (
            <div style={style} className={className} aria-labelledby={labeledBy}>
                <FormControl
                    autoFocus
                    className="dropdown-menu-version-input"
                    placeholder="&nbsp;find version"
                    onChange={this.handleChange}
                    // style={{ width: '100px !important' }}
                    style={{ width: '10.0rem !important' }}
                    value={value}
                />
                <ul className="list-unstyled dropdoown-item-available-versions">
                    {React.Children.toArray(children).filter(
                        child =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    }
}

class VersionDropodown extends React.Component {
    render() {
        const { remainingVersions, groupedByVersion } = this.props;

        return (

            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    ...
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-available-versions" as={CustomMenu}>
                    {
                        remainingVersions.map(version => {
                            return (
                                <Dropdown.Item
                                    key={version}
                                    href={groupedByVersion[version][0].link}
                                    className="dropdown-menu-version-item"
                                >
                                    {version.replace("release/", "").replace("release-", "")}
                                </Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown >

        );
    }
}


export default VersionDropodown;




