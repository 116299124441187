import React, { Component } from "react";
import groupBy from "lodash/groupBy";
import slice from "lodash/slice";
import drop from "lodash/drop";
import uniqueId from "lodash/uniqueId";
import { DEFAULT_CATEGORY } from "../../constants/constant";

import VersionDropdown from "./VersionDropdown";

import "./result.css";
import { compareVersion } from "../../utils";

class ResultItem extends Component {
  render() {
    const { data } = this.props;
    const groupedByVersion = groupBy(data, "version");

    let item = null;
    let availableVersionsList = null;
    const versions = Object.keys(groupedByVersion);

    if (groupedByVersion["latest"]) {
      item = groupedByVersion["latest"][0];
      const otherVersions = versions
        .filter((item) => item !== "latest")
        .sort(compareVersion);

      let top3Versions = [];
      let remainingVersions = [];
      if (otherVersions.length > 3) {
        top3Versions = slice(otherVersions, 0, 3);
        remainingVersions = drop(otherVersions, 3);
      }

      if (otherVersions.length > 0) {
        if (top3Versions.length > 0) {
          availableVersionsList = (
            <div className="card version-card">
              <div className="card-available-version">Available Versions</div>
              <div id={`version-dropdown-${this.props.id}`} className="row">
                {top3Versions.map((version, index) => (
                  <label
                    id={`available-versions_${index}-${this.props.id}`}
                    key={uniqueId()}
                    className="available-versions"
                  >
                    <a
                      href={groupedByVersion[version][0].link}
                      className="label version"
                    >
                      {version
                        .replace("release/", "")
                        .replace("release-", "")
                        .replace("current_version", "latest")}
                    </a>
                  </label>
                ))}
                <label
                  id={`version-dropdown-${this.props.id}`}
                  className="available-versions-dropdown-button"
                >
                  <VersionDropdown
                    remainingVersions={remainingVersions}
                    groupedByVersion={groupedByVersion}
                  />
                </label>
              </div>
            </div>
          );
        } else {
          availableVersionsList = (
            <div className="card version-card">
              <div className="card-available-version">Available Versions</div>
              <div className="row">
                {otherVersions.map((version) => (
                  <label key={uniqueId()} className="available-versions">
                    <a
                      href={groupedByVersion[version][0].link}
                      className="label version"
                    >
                      {version
                        .replace("release/", "")
                        .replace("release-", "")
                        .replace("current_version", "latest")}
                    </a>
                  </label>
                ))}
              </div>
            </div>
          );
        }
      }
    } else {
      item = groupedByVersion[versions[0]][0];
    }

    const manual_category = item["mannual_category"];
    const manual_project = item["project_in"];

    const title = item.title ? (
      <label
        className="search-result-title"
        dangerouslySetInnerHTML={{ __html: item.title }}
      ></label>
    ) : null;

    const content = item.highlight.content
      ? item.highlight.content.map((header) => (
          <p
            key={uniqueId()}
            className="search-result-texts"
            dangerouslySetInnerHTML={{ __html: header }}
          />
        ))
      : null;
    return (
      <>
        <div id={this.props.id} className="col-md-12 single-result">
          <div className="row resultItem">
            <div className="col-lg-9 col-md-9">
              <a href={item.link} className="list-group-item">
                <h5 className="list-group-item-heading">
                  <span
                    id={`guide_mannual-${this.props.id}`}
                    className="search-result-guide-path"
                  >
                    {`${manual_project} / ${
                      manual_category === DEFAULT_CATEGORY
                        ? ""
                        : manual_category + " / "
                    } ${item.display_name}`}
                    <p className=" version-current badge badge-secondary">
                      {item.version
                        .replace("release/", "")
                        .replace("release-", "")
                        .replace("current_version", "latest")}
                    </p>
                  </span>
                </h5>
                <div className="" style={{ marginTop: "1rem" /* "10px" */ }}>
                  {title}
                </div>
                <div>{content}</div>
              </a>
            </div>
            <div className="col-lg-3 col-md-3 search-version-card">
              {availableVersionsList}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ResultItem;
