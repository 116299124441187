import React from "react";
import { Switch, Tag, List, Avatar, Space, Typography } from "antd";
const { Text, Paragraph } = Typography;

const PlaybookItem = ({ item, onSwitchChecked, isChecked }) => {
  return (
    <List.Item
      actions={[
        <Switch
          checked={isChecked}
          onChange={(checked) => onSwitchChecked(item.entityId, checked)}
        />,
      ]}
    >
      <List.Item.Meta
        avatar={
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 48, xl: 48, xxl: 48 }}
            shape="square"
            src={`data:image/jpeg;base64,${item.dependencies[0].entityData.logoBase64}`}
          />
        }
        title={
          <div>
            <Space wrap>
              {item.entityName}
              <Tag style={{ fontWeight: "initial" }}>{item.category}</Tag>
            </Space>
          </div>
        }
        description={
          <Paragraph
            style={{ marginBottom: "0px" }}
            type="secondary"
            ellipsis={{
              rows: 1,
              expandable: true,
              symbol: "more",
            }}
          >
            {item.entityData.description}
          </Paragraph>
        }
      />
      <Space
        style={{ marginLeft: "24px" }}
        align="end"
        size={4}
        direction="vertical"
      >
        {" "}
        <Text type="secondary">Vendor</Text>
        <Text type="secondary">
          {item.dependencies[0].entityName || "Vendor name"}
        </Text>
      </Space>
    </List.Item>
  );
};

export default PlaybookItem;
