import React, { Component } from "react";
import { connect } from 'react-redux';
import Loader from "../components/common/Loading"
import { fetchAllProducts } from '../actions/Product';
import Search from "../components/Home/Search";
import Products from "../components/Home/Products";



class index extends Component {
  constructor() {
    super();
    this.state = {
      showCookieBar: true
    };

  }

  changeUrlForSearch = url => {
    this.props.history.push(url);
  };

  render() {
    const { allProducts, allManuals, allManualsTree } = this.props;
    if (!allManualsTree) {
      return (
        <div className="container-fluid">
          <div
            className="col-md-3 col-centered"
            style={{ float: "none", margin: "0 auto", paddingTop: 70, textAlignLast: "center", height: '85vh' }}
          >
            <Loader type="ThreeDots" color="#F0AD4E" />
          </div>
        </div>
      )
    }

    return (
      <>
        <Search changeUrlForSearch={this.changeUrlForSearch} />
        <Products products={allProducts} allManuals={allManuals} allManualsTree={allManualsTree} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  allProducts: state.products.all,
  allManuals: state.products.allManuals,
  allManualsTree: state.products.allManualsTree
})

export default connect(mapStateToProps, { fetchAllProducts })(index);
