import React, { Component } from "react";
import Header from "../components/common/Header";
import CardGrid from "../components/Plugins/CardGrid";
import axios from "axios";
import SERVER_URL from "../constants/app";

class Plugins extends Component {
  state = {
    categories: []
  };

  componentWillMount() {
    axios
      .get(SERVER_URL + `/api/v2/allplugins`, {
        withCredentials: true
      })
      .then(res => this.setState({ categories: res.data }));
  }
  render() {
    const { categories } = this.state;
    return (
      <>
        <section className="features">
          <div className="container">
            <Header title={"Plugins"} subtitle="" />
            <CardGrid data={categories} />
          </div>
        </section>
      </>
    );
  }
}

Plugins.propTypes = {};

export default Plugins;
