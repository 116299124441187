import apiCall from '../utils/apiCall';
export const FETCH_ALL_MANUALS = 'FETCH_ALL_MANUALS';
export const FETCH_RAW_MANUALS = 'FETCH_RAW_MANUALS';

export const fetchAllProducts = () => dispatch => {

    apiCall("/api/v2/allprojects")
        .then(res => {
            dispatch({
                type: FETCH_ALL_MANUALS,
                payload: res
            })
        })

    apiCall("/api/v2/allprojects?as_raw=True")
        .then(res => {
            dispatch({
                type: FETCH_RAW_MANUALS,
                payload: res
            })
        })


}


