import React from 'react';
import PropType from 'prop-types';
import "./Loading.css"
import LogoLoader from "../../assets/images/logo.svg";
const Loader = (props) => (
    <div>
        <div id="loader-wrapper">
            <div className="loader-container">
                <div className="loader-text">
                    <h3 style={{ color: props.color }}> {props.title || ''} </h3>
                </div>
                <div className="loader-spinner" />
                <div className="loader-logo-container">
                <img src={LogoLoader} className="logo" alt="" />
                </div>
            </div>
        </div>
    </div>
);

Loader.propTypes = {
    title: PropType.string,
    color: PropType.string
};

Loader.defaultProps = {
    title: '',
    color: ''
};

export default Loader;
